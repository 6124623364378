import { Button } from '@/models'

export default function parseButtonParameters(
  button: Button,
  params: Record<string, any>
) {
  const dstParams = { ...params }
  if (button && button.parameters) {
    for (const btnParam of button.parameters) {
      dstParams[btnParam.parameterName] =
        params[btnParam.value] || btnParam.value
    }
  }
  return dstParams
}
