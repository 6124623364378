import gql from 'graphql-tag'

export const FormCreateFragment = gql`
  fragment FormCreate on Form {
    _id
    environmentId
    collectionId
  }
`

export const FormUpdateFragment = gql`
  fragment FormUpdate on Form {
    name
    title
    type
    updateVariableName
    updateFilterId
    updateFilterParamNames
    hideEmpty
    emptyFormText
    reset
    submitButtonText
    submitButtonIcon
    submitButtonColor
    submitButtonBgColor
    resetButtonText
    buttonAlignment
    onSuccessViewPath
    onSuccessExternalTab
    onSuccessEnvironmentVariables
    postItemToUrl
    dynamicViewParams
    hideSubmitButton
  }
`

export const FormFragment = gql`
  fragment Form on Form {
    ...FormCreate
    ...FormUpdate
    serializedParams
    fieldsList {
      fieldName
      type
      editableLabel
      showField
    }
  }
  ${FormCreateFragment}
  ${FormUpdateFragment}
`

export const FormEditFragment = gql`
  fragment FormEdit on Form {
    ...FormUpdate
    roles
    afterHooksIds
    validationsIds
    validationsMode
    shouldStopHooksOnError
    shouldDeleteItemOnError
    requireTwoFactor
    fields {
      fieldName
      type
      optional
      fixed
      showField
      parameterName
      requiredField
      requiredValue
      requiredParameter
      requiredType
      editableLabel
      indicatorId
      editableDefaultValue
      indicatorDefaultValue
      sizeSmall
      sizeMedium
      sizeLarge
    }
  }
  ${FormUpdateFragment}
`
