import { Button } from '@/models'
import store from '@/store'
import openLink from '../route/openLink'
import resolveLink from '../route/resolveLink'
import parseButtonParameters from './parseButtonParameters'
import runtimeParams from '@/utils/runtime/runtimeParams'
import i18n from '@/plugins/i18n'

const $t = (localePath: string) => {
  return i18n.t(localePath).toString()
}

export default async function handleButtonSuccess(
  button: Button,
  params: Record<string, any>,
  result: any
) {
  await store.dispatch('snackbar/showSnackbar', {
    text: button.onSuccessMessage || $t('handleButtonSuccess.complete'),
    timeout: 3000,
  })
  // Extract result parameters
  const resultParams = {
    ...params,
    ...parseButtonParameters(button, params),
    ...result,
    environmentId: button.environmentId,
  }
  // const viewParams = parseButtonParameters(button, resultParams)
  // Redirect to target view
  if (button.url) await openLink(button.url, resultParams)
  // Post item data to URL
  if (button.buttonType === 'postItemToUrl' && button.postItemToUrl) {
    const targetUrl = resolveLink(button.postItemToUrl, resultParams)
    await fetch(targetUrl.href, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resultParams),
    })
  }
  // Set view parameters
  if (button.setViewParameters) {
    const newParams: Record<string, any> = {}
    for (const param of button.setViewParameters) {
      newParams[param.value] = resultParams[param.parameterName]
    }
    runtimeParams.set(newParams)
  }
}
