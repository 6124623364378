import { render, staticRenderFns } from "./View.vue?vue&type=template&id=76200e2b&scoped=true&"
import script from "./View.vue?vue&type=script&lang=ts&"
export * from "./View.vue?vue&type=script&lang=ts&"
import style0 from "./View.vue?vue&type=style&index=0&id=76200e2b&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76200e2b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VContainer,VIcon,VSkeletonLoader})
